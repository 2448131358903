<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('externalTraining.faq_List')}}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('externalTraining.faq_name')"
                        label-for="faq_setup"
                        >
                        <v-select name="faq_setup"
                        v-model="search.faq_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= faqSetupLists
                        />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('externalTraining.faq_List')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + 1) }}
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { faqSetupList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
            search: {
                faq_id: 0
            }
        }
    },
    computed: {
        faqSetupLists: function () {
          return this.$store.state.ExternalUserTraining.traineeTranerObj.faqSetupList.filter(item => item.status === 1)
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('elearning_config.faq_name'), class: 'text-left' },
                { label: this.$t('externalTraining.answer'), class: 'text-left' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'faq_name_bn' },
                { key: 'description_bn' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'faq_name' },
                { key: 'description' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, faqSetupList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList',response.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
